.NewCalcContainer {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    background-color: white;
    padding: 0px;
    text-align: center;
    box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.06);
    font-family: Montserrat, sant-serif;
    border-radius: 8px;
}

.optionsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-bottom: 20px;
}

.optionButton {
    flex: 1;
    cursor: pointer;
    min-height: 3rem;
    padding: 15px 12px;
    max-width: 200px;
    color: #474c56;
    border-bottom: 2px solid gray;
    font-weight: bold;
    font-size: 24px;
}

.optionButton:first-child {
    border-right: 1px solid lightgray;
}

.optionButton.selectedChoice {
    font-weight: 600;
    color: #476eee;
    border-bottom: 2px solid #476eee;
}

.operationSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 40px
}

.calc-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calc-select-currency {
    width: 100%;
    min-height: 50px;
    margin-bottom: 10px;
    border-color: rgb(240, 240, 240);
    border-radius: 6px;
    border-width: 1px;
    height: 100%;
    justify-content: space-between;
}

.calc-select-currency-wrapper {
    width: 100%;
    right: 0px;
    font-weight: 600;
    font-size: 28px;
    border-radius: 0px 5px 5px 0px;
    color: rgb(191, 191, 191);
    font-family: Montserrat;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
}

.calc-select-currency-label {
    font-size: 12px;
    font-weight: 400;
    padding-left: 20px;
}

.calc-currency-info {
    display: flex;
    flex-direction: row;
}

.calc-currency-logo {
    height: 32px;
    display: inline-block;
    padding-top: 8px;
    padding-right: 15px;
    box-sizing: border-box;
    padding-left: 30px;
}

.calc-exchange-rate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 15px;
    color: #3C4A5B;
    opacity: 0.7;
    border-radius: 5px;
    font-weight: 600;
    font-family: Montserrat;
    height: 40px;
}

.calc-exchange-rate-img {
    max-width: 15px;
    margin: 0px 5px;
}

.calc-confirm-btn {
    border: none;
    outline: none;
    background-color: #1a4bea;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #fff;
    font-family: Montserrat;
    font-weight: 700;
    padding: 16px 32px;
    margin: 10px 0px;
    border-radius: 8px;
    font-size: 22px;
    text-align: center;
    cursor: pointer;
    width: 100%;
}


.calc-legend-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.calc-legend-container>p {
    margin: 20px;
    font-family: Montserrat;
    color: #3C4A5B;
    font-size: 14px;
    text-align: center;
}

.calc-input-container {
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;
    border-color: rgb(240, 240, 240);
    border-radius: 6px;
    border-width: 1px;
    height: 100%;
    justify-content: space-between;
    display: flex;
}

.calc-input-left {
    width: 60%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    padding-left: 20px;
    padding-top: 8px;
}

.calc-input-left-label {
    color: rgb(191, 191, 191);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
}

.calc-input {
    border-width: 0px;
    display: block;
    font-weight: bold;
    -moz-appearance: textfield;
    appearance: textfield;
}

.calc-input:focus {
    outline: none;
}

.calc-input-right-first {
    width: 45%;
    align-self: center;
    color: rgb(0, 160, 0);
    font-weight: bold;
}

.calc-input-currency {
    text-align: right;
    font-size: 14px;
    margin-block: 0px;
    margin-right: 10px;
}

.calc-input-right-second {
    width: 45%;
    align-self: center;
    color: orange;
    font-weight: bold;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
}

.calc-input-right-second-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
}

.calc-input-right-img {
    height: 20px;
    margin-right: 5px;
}

.calc-arrow-svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}

.calc-input-minimum-label {
    margin-top: -20px;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 5px;
    color: red;
    font-size: 12px;
    width: 100%;
}

.calc-hover:hover {
    background-color: #deebff;
}

.calc-selected-option {
    background-color: #2684FF;
}

.calc-list-wrapper-large {
    position: relative;
    box-sizing: border-box;
}

.calc-select-currency-list-large {
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    max-height: 270px;
    overflow-y: auto;
}

.calc-list-wrapper-small {
    box-sizing: border-box;
    display: flex;
    margin-right: -67px;
}

.calc-select-currency-list-small {
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: -18px;
    width: 127px;
    max-height: 250px;
    overflow-y: auto;
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    color: orange;
    font-weight: bold;
    text-align: right;
    font-size: 14px;
    cursor: pointer;
}

.calc-select-currency-wrapper-small {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
}



/* Others */

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.open-menu {
    transform: rotate(180deg);
}

.color-white {
    color: white;
}

/* Loader */

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #1a4bea;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1a4bea transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



/* Responsive */

@media screen and (max-width: 768px) {
    .NewCalcContainer {
        max-width: 380px;
        margin-bottom: 300px;
        padding-bottom:100px;
    }

    .calc-select-currency-list-small{
        max-height: 100px;
    }
}